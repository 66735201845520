
import { Options, Prop, Vue } from "vue-property-decorator";

@Options({})
export default class extends Vue {
  @Prop() private submitform!: any;
  @Prop() private label!: any;
  @Prop() private rank!: any;
  @Prop() private selectColumns!: any;

  private selectColumnsTemp = [];
  private secondColumnsTemp = [];
  private show = false;
  private fieldValueArea = "";
  private cascaderValue = "";
  private options = [];

  initialData(options: any, source: any) {
    if (source === [] || source === undefined) {
      this.$notify({
        type: "danger",
        message: "请重试！确认选择检查项目后选择检查部位",
      });
    } else {
      source.forEach((element: any) => {
        options.push({
          text: element.name,
          value: element.id,
          code: element.code,
          id: element.id,
        });
      });
    }
  }

  onAreaFinish(select: any) {
    const { selectedOptions } = select;
    this.show = false;
    this.fieldValueArea = selectedOptions
      .map((option: any) => option.text)
      .join("");
    var mapOption = selectedOptions.map((option: any) => option.value).join("");
    this.submitform.checkArea = mapOption;
    this.submitform.speechArea = selectedOptions.map(
      (option: any) => option.id
    )[0];
    this.submitform.sArea = selectedOptions
      .map((option: any) => option.code)
      .join("");
    this.$emit("areaChange");
  }

  initAreaPopup() {
    this.options = [];
    this.selectColumnsTemp = this.selectColumns;
    this.initialData(this.options, this.submitform.checkAreaColumns);
  }
}
