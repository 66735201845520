import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_picker = _resolveComponent("van-picker")!
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_van_field, {
      modelValue: _ctx.result,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.result) = $event)),
      "is-link": "",
      readonly: "",
      rules: [{ required: true, message: '请选择检查单类型' }],
      required: "",
      name: "picker",
      label: "检查单类型",
      placeholder: "请选择检查单",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showPicker = true))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_van_popup, {
      round: "",
      show: _ctx.showPicker,
      "onUpdate:show": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showPicker) = $event)),
      position: "bottom",
      onOpen: _ctx.openPopup
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_picker, {
          columns: _ctx.columns,
          onConfirm: _ctx.onConfirm,
          onCancel: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showPicker = false))
        }, null, 8, ["columns", "onConfirm"])
      ]),
      _: 1
    }, 8, ["show", "onOpen"])
  ]))
}