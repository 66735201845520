
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import * as api from "@/api/safecheck";

@Options({})
export default class extends Vue {
  @Prop() private submitform!: any;
  private personNameWatch = new Date().getTime();
  private show = false;
  private flag = false;
  private personName = "";
  private refreshing = false;
  private checkList: any[] = [];
  private loading = false;
  private finished = false;
  private result: any[] = [];
  private fieldValue = "";

  @Watch("personName")
  onPersonNameChanged(newValue: string, oldValue: string) {
    this.refreshing = true;
    this.onRefresh();
  }
  @Watch("result")
  onResultChanged(newValue: string, oldValue: string) {
    if (newValue.length === 0) {
      this.flag = false;
    } else {
      this.flag = true;
    }
  }

  protected showPopup() {
    this.show = true;
  }

  protected closePopup() {
    this.show = false;
  }

  protected onLoad() {
    setTimeout(() => {
      if (this.refreshing) {
        this.checkList = [];
        this.refreshing = false;
      }
      api
        .pageListCheckUser({
          pageNum: parseInt(this.checkList.length / 20 + "") + 1,
          pageSize: 20,
          projectId: this.$store.state.project.projectinfo.projectId,
          name: this.personName,
        })
        .then((res) => {
          this.checkList = [...this.checkList, ...res.data.records];
          this.loading = false;
          if (this.checkList.length >= res.data.total) {
            this.finished = true;
          }
        });
    }, 500);
  }

  protected onFinish() {
    this.show = false;
    this.fieldValue = this.result.map((option) => option.name).join(",");
    this.submitform.checkUserName = this.result
      .map((option) => option.name)
      .join(",");
    let checkUserId = this.result.map((option) => option.userId).join(",");
    this.$emit("inputCheckBox", checkUserId);
  }

  onRefresh() {
    this.finished = false;
    this.loading = true;
    this.onLoad();
  }
}
