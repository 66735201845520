
import { Options, Vue, Prop } from "vue-property-decorator";

@Options({})
export default class extends Vue {
  @Prop() private checkIdscolumns!: any;

  private result = "";
  private columns: any[] = [];
  private showPicker = false;

  openPopup() {
    this.checkIdscolumns.map((item: any) => {
      this.columns.push(item.name);
    });
  }

  onConfirm(value: any) {
    let index = this.checkIdscolumns.findIndex(
      (item: any) => item.name === value
    );
    this.result = value;
    this.showPicker = false;
    this.$emit("checkIdsonConfirm", this.checkIdscolumns[index].id);
  }
}
