import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_cascader = _resolveComponent("van-cascader")!
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_van_field, {
      modelValue: _ctx.fieldValueArea,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fieldValueArea) = $event)),
      label: _ctx.label,
      rules: [{ required: true, message: '请选择检查部位' }],
      required: "",
      "is-link": "",
      readonly: "",
      placeholder: "请选择检查部位",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.show = true))
    }, null, 8, ["modelValue", "label"]),
    _createVNode(_component_van_popup, {
      show: _ctx.show,
      "onUpdate:show": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.show) = $event)),
      round: "",
      position: "bottom",
      onOpened: _ctx.initAreaPopup
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_cascader, {
          modelValue: _ctx.cascaderValue,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.cascaderValue) = $event)),
          options: _ctx.options,
          title: "请选择检查部位",
          onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.show = false)),
          onFinish: _ctx.onAreaFinish
        }, null, 8, ["modelValue", "options", "onFinish"])
      ]),
      _: 1
    }, 8, ["show", "onOpened"])
  ]))
}