import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_datetime_picker = _resolveComponent("van-datetime-picker")!
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_van_field, {
      modelValue: _ctx.currentDate,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentDate) = $event)),
      value: _ctx.currentDate,
      rules: [{ required: true, message: '请指定限定整改时间' }],
      required: "",
      readonly: "",
      "is-link": "",
      clickable: "",
      label: "限定整改时间",
      placeholder: "请指定限定整改时间",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.show = true))
    }, null, 8, ["modelValue", "value"]),
    _createVNode(_component_van_popup, {
      show: _ctx.show,
      "onUpdate:show": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.show) = $event)),
      position: "bottom"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_datetime_picker, {
          modelValue: _ctx.currentDay,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentDay) = $event)),
          "min-date": _ctx.minDate,
          type: "date",
          title: "请指定限定整改时间",
          onCancel: _cache[3] || (_cache[3] = ($event: any) => (_ctx.show = false)),
          onConfirm: _ctx.pickerconfirm
        }, null, 8, ["modelValue", "min-date", "onConfirm"])
      ]),
      _: 1
    }, 8, ["show"])
  ]))
}