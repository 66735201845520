
import { Options, Prop, Vue } from "vue-property-decorator";

@Options({})
export default class extends Vue {
  @Prop() private projectIdscolumns!: any;
  private result = "";
  private columns: any[] = [];
  private showPicker = false;

  openPopup() {
    this.projectIdscolumns.map((item: any) => {
      this.columns.push(item.projectName);
    });
  }

  onConfirm(value: any) {
    this.result = value;
    this.showPicker = false;
  }
}
