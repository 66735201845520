
import { Options, Prop, Vue } from "vue-property-decorator";

@Options({})
export default class extends Vue {
  @Prop() private submitform!: any;
  @Prop() private checkPersonLabel!: any;
  @Prop() private columns!: any;
  @Prop() private domainList!: any;

  private list = [];
  private speechContent = "";
  private result = [];
  private show = false;
  private fieldValue = "";
  private loading = false;
  private finished = false;

  protected toggle(index: any) {
    // console.log("xxxxxx");
  }

  onFinish(select: any) {
    const { selectedOptions } = select;
    this.show = false;
    this.fieldValue = this.result.map((option: any) => option.code).join(",");
    this.submitform.speechCode = this.result
      .map((option: any) => option.code)
      .join(",");
    const targetDomain: any[] = [];
    this.result.forEach((rs: any) => {
      const domainsTemp = this.domainList.filter((dm: any) => {
        return dm.code === rs.code;
      });
      if (domainsTemp === null || domainsTemp.length === 0) {
        targetDomain.push({
          inspectCode: rs.code,
          inspectContent: rs.name,
          value: [],
        });
      } else {
        targetDomain.push(domainsTemp[0]);
      }
    });
    // this.domainList = targetDomain
    this.$emit("update:domainList", targetDomain);
  }
  initPopup() {
    if (this.columns === undefined || this.columns.length === 0) {
      this.$notify({
        type: "danger",
        message: "请重试！确认选择检查项目、检查部位后选择检查用语",
      });
      this.$emit("areaChange");
    } else {
      this.list = this.columns;
    }
  }
}
