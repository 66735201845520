
import { Options, Vue, Prop } from "vue-property-decorator";

@Options({})
export default class extends Vue {
  @Prop() private billIdscolumns!: any;

  private result = "";
  private columns: any[] = [];
  private showPicker = false;

  openPopup() {
    this.billIdscolumns.map((item: any) => {
      this.columns.push(item.name);
    });
  }

  onConfirm(value: any) {
    let index = this.billIdscolumns.findIndex(
      (item: any) => item.name === value
    );
    this.result = value;
    this.showPicker = false;
    this.$emit("billIdsonConfirm", this.billIdscolumns[index].id);
  }
}
