
import { Options, Prop, Vue } from "vue-property-decorator";
import * as api from "@/api/safecheck";

@Options({})
export default class extends Vue {
  @Prop() private selectColumns!: any;
  @Prop() private secondColumns!: any;
  @Prop() private label!: any;
  @Prop() private submitform!: any;
  @Prop() private rank!: any;
  @Prop() private areaform!: any;
  private fieldValue = "";
  private show = false;
  private cot = 0;
  private rankCount = 0;
  private selectColumnsTemp = [];
  private secondColumnsTemp = [];
  private cascaderValue = "";
  private options = [];

  initialData(options: any, source: any, secondSource: any, rank: any) {
    let childrenOptions: any[] = [];
    source.forEach((element: any) => {
      childrenOptions = [];
      if (rank === 1) {
        options.push({
          text: element.name,
          value: element.id,
          code: element.code,
          id: element.id,
          children: childrenOptions,
        });
        secondSource.forEach((secondElement: any) => {
          if (element.id === secondElement.parentId) {
            childrenOptions.push({
              text: secondElement.name,
              value: secondElement.id,
              code: secondElement.code,
              id: secondElement.id,
            });
          }
        });
      } else {
        if (
          this.submitform.targetDept.toString() === element.parentId.toString()
        ) {
          options.push({
            text: element.name,
            value: element.id,
            code: element.code,
          });
        }
      }
    });
  }
  protected onFinish(select: any) {
    const { selectedOptions } = select;
    this.show = false;
    this.fieldValue = selectedOptions
      .map((option: any) => option.text)
      .join("/");
    this.submitform.sDeptItem = selectedOptions
      .map((option: any) => option.code)
      .join("-");
    var ids = selectedOptions.map((option: any) => option.id);
    this.submitform.speechDept = ids[0];
    this.submitform.speechItem = ids[1];
    var mapOption = selectedOptions
      .map((option: any) => option.value)
      .join("|");
    this.areaform.areaItem = mapOption.split("|")[1];
    api
      .checkAreaList({
        ...this.areaform,
      })
      .then((res: any) => {
        if (res.code === 0) {
          this.submitform.checkAreaColumns = res.data;
        }
      });
    this.$emit("deptChange");
  }
  protected initPopup() {
    this.options = [];
    this.selectColumnsTemp = this.selectColumns;
    this.secondColumnsTemp = this.secondColumns;
    this.initialData(
      this.options,
      this.selectColumnsTemp,
      this.secondColumnsTemp,
      this.rank
    );
  }
}
