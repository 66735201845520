import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_sticky = _resolveComponent("van-sticky")!
  const _component_van_checkbox = _resolveComponent("van-checkbox")!
  const _component_van_cell = _resolveComponent("van-cell")!
  const _component_van_cell_group = _resolveComponent("van-cell-group")!
  const _component_van_checkbox_group = _resolveComponent("van-checkbox-group")!
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_van_field, {
      modelValue: _ctx.fieldValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fieldValue) = $event)),
      label: _ctx.checkPersonLabel,
      value: _ctx.speechContent,
      rules: [{ required: true, message: '请选择检查术语' }],
      required: "",
      "is-link": "",
      readonly: "",
      placeholder: "请选择检查用语",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.show = true))
    }, null, 8, ["modelValue", "label", "value"]),
    _createVNode(_component_van_popup, {
      show: _ctx.show,
      "onUpdate:show": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.show) = $event)),
      round: "",
      position: "bottom",
      onOpened: _ctx.initPopup
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_sticky, null, {
          default: _withCtx(() => [
            _createVNode(_component_van_field, {
              modelValue: _ctx.speechContent,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.speechContent) = $event)),
              label: "检查用语",
              placeholder: "请选择检检查用语",
              clearable: "",
              disabled: ""
            }, {
              button: _withCtx(() => [
                _createElementVNode("span", {
                  style: {"color":"#1989fa"},
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onFinish && _ctx.onFinish(...args)))
                }, "确认")
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_van_checkbox_group, {
          modelValue: _ctx.result,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.result) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_van_cell_group, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
                  return (_openBlock(), _createBlock(_component_van_cell, {
                    key: item.id,
                    title: `${item.code} ${item.name}`,
                    clickable: "",
                    onClick: ($event: any) => (_ctx.toggle(index))
                  }, {
                    "right-icon": _withCtx(() => [
                      _createVNode(_component_van_checkbox, {
                        ref: "checkboxes",
                        name: item
                      }, null, 8, ["name"])
                    ]),
                    _: 2
                  }, 1032, ["title", "onClick"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["show", "onOpened"])
  ]))
}